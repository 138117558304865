import { Link, useParams } from "react-router-dom";
import { newsHighlights } from "../../../data/newsData/newsHighlights";
import React, { useState, StrictMode, useEffect } from "react";
import Carousel from "react-simply-carousel";
import { carouselData } from "../../../data/carouseIData";
import ReactSimplyCarousel from "react-simply-carousel";
import axios from "axios";
import { Article } from "@/src/modules/homepage/definitions/types";
import dayjs from "../../../modules/shared/libs/dayjs";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import IcLinkSVG from "../../../modules/shared/component/IcLinkSVG";
import { useTheme } from "../../../modules/shared/context/ThemeContext/ThemeProviderContext";
import { Theme } from "../../../modules/shared/definitions/enum";
import PopoverDynamic from "../../../modules/shared/component/dialog/PopoverDynamic";
import copyToClipboard from "../../../modules/shared/utilities/copyToClipboard";

interface ImageDetail {
  image: string;
}

const NewsDetailContent: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const [articlesDetail, setArticlesDetail] = useState<Article | null>(null);
  const [imagesDetail, setImagesDetail] = useState<ImageDetail[]>([]);

  useEffect(() => {
    const getArticles = async () => {
      const response = await axios.get(
        `https://divhubinter.polri.go.id/api/artikel/${id}`,
      );
      setArticlesDetail(response.data.artikel[0]);
    };

    getArticles();

    const getImages = async () => {
      const response = await axios.get(
        `https://divhubinter.polri.go.id/api/artikel/${id}`,
      );
      setImagesDetail(response.data.image);
    };

    getImages();
  }, [id]);

  return (
    <div className="container phone-s:max-w-full laptop:w-11/12 mx-auto py-12">
      <div className="card bg-neutral shadow-xl">
        <div className="card-body">
          <div className="text-sm breadcrumbs " style={{ color: "#999" }}>
            <ul>
              <li>
                <Link to={"/berita"}>Berita</Link>
              </li>
              <li>News</li>
            </ul>
          </div>
          <h2 className="card-title text-4xl">
            {articlesDetail?.artikel_judul}
          </h2>
          <div className="text-sm text-white">
            {dayjs(articlesDetail?.artikel_tanggal).format("DD MMMM YYYY")}
          </div>
          <div className="flex items-center space-x-2 mb-6">
            <PopoverDynamic
              renderPopoverContent={() => (
                <div className="tooltip-headless font-medium rounded-lg absolute -top-[calc(100%+68px)] left-1 mt-2 max-w-[300px] border-2 border-black bg-white px-5 py-3 text-sm text-black">
                  Link berhasil disalin
                </div>
              )}
            >
              <IcLinkSVG
                fill={theme === Theme.DARK ? "#FFF" : "#000"}
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  // copy current url using copyToClipboard function
                  copyToClipboard(window.location.href);
                }}
              />
            </PopoverDynamic>

            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={24} />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <XIcon size={24} />
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={24} />
            </WhatsappShareButton>
          </div>
          <div>
            <ReactSimplyCarousel
              containerProps={{ className: "flex" }}
              activeSlideIndex={activeSlideIndex}
              onRequestChange={setActiveSlideIndex}
              itemsToShow={1}
              itemsToScroll={1}
              forwardBtnProps={{
                className: "btn btn-circle",
                //here you can also pass className, or any other button element attributes
                style: {
                  alignSelf: "center",
                },
                children: <span>{`>`}</span>,
              }}
              backwardBtnProps={{
                className: "btn btn-circle",
                //here you can also pass className, or any other button element attributes
                style: {
                  alignSelf: "center",
                },
                children: <span>{`<`}</span>,
              }}
              responsiveProps={[
                {
                  itemsToShow: 1,
                  itemsToScroll: 1,
                },
              ]}
              speed={400}
              easing="linear"
            >
              <div>
                <div style={{ width: 1280, height: 768 }}>
                  <img
                    src={
                      Array.isArray(imagesDetail) && imagesDetail.length > 0
                        ? "https://divhubinter.polri.go.id" +
                          imagesDetail[0].image
                        : "https://divhubinter.polri.go.id" +
                          articlesDetail?.artikel_thumbnail
                    }
                    alt=""
                    style={{ width: 1280, height: 768 }}
                  />
                </div>
              </div>

              {/* {imagesDetail.map((item) => (
                <div key={item.image_id}>
                  <div style={{ width: 1120, height: 600 }}>
                    <img
                      src={item.image}
                      alt="Pizza"
                      style={{ width: 1120, height: 600 }}
                    />
                  </div>
                </div>
              ))} */}
            </ReactSimplyCarousel>
          </div>
          <div
            className="text-justify mt-6"
            dangerouslySetInnerHTML={{
              __html: articlesDetail?.artikel_konten || "",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetailContent;
